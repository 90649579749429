import { Injectable } from '@angular/core';
import { ClientesUpuouData } from '../data/clientes-upuou';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';


@Injectable()
export class ClientesUpuouService extends ClientesUpuouData {

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService, 
    private datePipe: DatePipe
  ) {
    super(); 
  }

  customers = [];
  companyusers = [];
  companystatisticscustomers = [];
  companystatisticscards = [];
  subscriptionlist = [];
  lastLogin = [];

  async searchCustomer(filters) {

    try {
      const response = await this.http.put(environment.urlbase + '/admin/v1/company/list', filters).toPromise();

      let dataReturn = response as any[];

      if (dataReturn['data']) {
        dataReturn['data'].forEach(customer => {
          customer.createdAt = this.datePipe.transform(new Date(customer.createdAt), 'dd/MM/yyyy HH:mm:ss');
        });
      }
      
      return this.customers = dataReturn;
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async editBonusDays(companyId, bonusDays) {
    try {

      const data = {
        companyId,
        bonusDays
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/bonusdays', data).toPromise();
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async requestPasswordConfirmationCode(email) {
    try {

      const data = {
        email
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/requestpassword', data).toPromise();
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async editUserPassword(confirmCode, email, newPassword) {
    try {

      const data = {
        confirmCode,
        email,
        newPassword
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/userpassword', data).toPromise();

      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async publishCompany(companyId, publishStatus) {
    try {

      const data = {
        companyId,
        publishStatus
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/publish', data).toPromise();
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async getUsers(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/userlist', data).toPromise();
      return this.companyusers = response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async getLastLogin(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/user', data).toPromise();
      return this.lastLogin = response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async getCompanyStatisticsCustomers(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/datastatistics/customers', data).toPromise();
      return this.companystatisticscustomers = response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async getCompanyStatisticsCards(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/datastatistics/cards', data).toPromise();
      return this.companystatisticscards = response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async getSubscriptionList(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/subscriptionlist', data).toPromise();
      return this.subscriptionlist = response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async cancelAccountSubscription(companyId) {
    try {

      const data = {
        companyId
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/account/cancelsubscription', data).toPromise();
      
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

  async createAccountCustomSubscription(companyId, totalCustomer, totalSite, totalAttendant, value, description) {
    try {

      const data = {
        companyId,
        totalCustomer,
        totalSite,
        totalAttendant,
        value,
        description
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/account/createcustomsubscription', data).toPromise();
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }
  

  async updateBonusDays(companyId, bonusDays) {
    try {

      const data = {
        companyId,
        bonusDays
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/bonusdays', data).toPromise();
      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }
  }

}


import { Observable } from 'rxjs';

export interface PieChart {
  percent: number;
  totalMoney: number;
  totalCustomer: number;
  signatureType: string;
}

export interface PieChartData{
  totalMoney: number;
  totalCustomer: number;
  pieChart: PieChart[];
  totalPurchases: number;
}

export abstract class ReceitaData {
  abstract getReceitaClientesPieChartData(selectedDateRange: string): Promise<PieChartData>;
}

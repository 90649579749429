
export abstract class ClientesUpuouData {
  abstract searchCustomer(filters?): Promise<any[]>;
  abstract editBonusDays(companyId, bonusDays): Promise<any[]>;
  abstract requestPasswordConfirmationCode(email): Promise<any[]>;
  abstract editUserPassword(confirmCode, email, newPassword): Promise<any[]>;
  abstract publishCompany(companyId, publishStatus): Promise<any[]>;
  abstract getUsers(companyId): Promise<any[]>;
  abstract getLastLogin(companyId): Promise<any[]>;
  abstract getCompanyStatisticsCustomers(companyId): Promise<any[]>;
  abstract getCompanyStatisticsCards(companyId): Promise<any[]>;
  abstract getSubscriptionList(companyId): Promise<any[]>;
  abstract cancelAccountSubscription(companyId): Promise<any[]>;
  abstract createAccountCustomSubscription(companyId, totalCustomer, totalSite, totalAttendant, value, description): Promise<any[]>;
  abstract updateBonusDays(companyId, bonusDays): Promise<any[]>;
}

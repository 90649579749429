import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { PieChart, PieChartData, ReceitaData } from '../data/receita-clientes-chart';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';

@Injectable()
export class ReceitaClientesService extends ReceitaData {


  totalMoney: number;
  totalCustomer: number;
  pieChart: PieChart[];

  constructor(private toastrService: NbToastrService,
    private http: HttpClient ) {
    super();
  }

  async getReceitaClientesPieChartData(selectedDateRange: string) {

    try {

      let params = {
        selectedDateRangeStart: selectedDateRange && JSON.parse(selectedDateRange).start ? JSON.parse(selectedDateRange).start: "",
        selectedDateRangeEnd: selectedDateRange && JSON.parse(selectedDateRange).end ? JSON.parse(selectedDateRange).end: "",
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/subscription/revenue', params).toPromise();

      response['pieChart'].forEach((pieData) => {
        if (pieData.percent == null) {
          pieData.percent = 0;
        }
      });

      return response as PieChartData;

    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }    

  }
}

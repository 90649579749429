import { Observable } from 'rxjs';

export interface ClientesMapeamentoUso {
  name: string;
  companyLegalRegister: string;
  companyId: string;
  createdAt: Date;
  public: boolean;
}

export interface ClientesMapeamentoUsoDataComplete {
  data: ClientesMapeamentoUso[];
  totalRecords: number;
  totalRecordsPage: number;
  hasNext: boolean;
}

export abstract class ClientesMapeamentoUsoData {
  abstract getClientesMapeamentoUsoData(selectedDateRange: string,  searchTerm: string, pagesNumber?: number, pageSize?: number): Promise<ClientesMapeamentoUsoDataComplete>;
}

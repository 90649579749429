import { Injectable } from '@angular/core';
import { ClientesMapeamentoUso, ClientesMapeamentoUsoData, ClientesMapeamentoUsoDataComplete } from '../data/clientes-mapeamento-uso-upuou';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClientesMapeamentoUsoService extends ClientesMapeamentoUsoData {

  data = {};

  constructor(private toastrService: NbToastrService,
              private http: HttpClient ) {
    super();
  }

  async getClientesMapeamentoUsoData(selectedDateRange: string, searchTerm: string, pageNumber: number, pageSize: number) {

    try {
      let params = {
        selectedDateRangeStart: selectedDateRange && JSON.parse(selectedDateRange).start ? JSON.parse(selectedDateRange).start: "",
        selectedDateRangeEnd: selectedDateRange && JSON.parse(selectedDateRange).end ? JSON.parse(selectedDateRange).end: "",
        searchTerm: searchTerm,
        pageNumber: pageNumber,
        pageSize: pageSize
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/mapping', params).toPromise();

      return response as ClientesMapeamentoUsoDataComplete;

    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }

  }
}

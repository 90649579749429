import { Observable } from 'rxjs';
import { ProfitChart  } from './profit-chart';

export interface ConversaoDeClienteSummaryData {
  title: string;
  value: number;
}

export abstract class ConversaoDeClientesData {
  abstract getConversaoClientesChartSummary(selectedDateRange: string): Promise<any[]>;
  abstract getConversaoClientesChartData(selectedDateRange: string, period: string): Promise<any[]>;
}

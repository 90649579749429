import { Injectable } from '@angular/core';
import { ConversaoClientesChart, ConversaoClientesChartData } from '../data/conversao-clientes-chart';
import { PeriodsService } from '../mock/periods.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';

@Injectable()
export class ConversaoClientesChartService extends ConversaoClientesChartData {

  private data = {};

  constructor(private period: PeriodsService,
              private http: HttpClient,
              private toastrService: NbToastrService) {
    super();
  }

  async getConversaoClientesData(selectedDateRange: string, period: string) {

    this.data = { 
      day: {
        chartLabel: [],
        data: [
          [], //Assinados - Considere a posição do chartLabel, aqui seria por dia dentro daterange selecionado 
          [], // Trials
          [], // Cancelados
        ],
      },
      month: {
        chartLabel: [],
        data: [
          [], //Assinados - Considere a posição do chartLabel, aqui seria por mês dentro daterange selecionado 
          [], // Trials
          [], // Cancelados
        ],
      },
    };

    try {

      let params = {
        period: period,
        selectedDateRangeStart: selectedDateRange && JSON.parse(selectedDateRange).start ? JSON.parse(selectedDateRange).start: "",
        selectedDateRangeEnd: selectedDateRange && JSON.parse(selectedDateRange).end ? JSON.parse(selectedDateRange).end: "",
      }

      let data = [];

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/conversion/chart', params).toPromise();

      data.push(response['data']['assinadas']);
      data.push(response['data']['trials']);
      data.push(response['data']['canceladas']);

      if (period === 'Dia') {
        this.data['day']['chartLabel'] = response['chartLabel'];
        this.data['day']['data'] = data;
        return this.data['day'];
      } else if (period === 'Mês' ) {
        this.data['month']['chartLabel'] = response['chartLabel'];
        this.data['month']['data'] = data;
        return this.data['month'];
      } 

    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }

  }

}

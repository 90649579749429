import { Injectable } from '@angular/core';
import { ClientesPublicados, ClientesPublicadosData, ClientesPublicadosDataComplete } from '../data/clientes-publicados-upuou';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClientesPublicadosService extends ClientesPublicadosData {

  data = {};

  constructor(private toastrService: NbToastrService,
              private http: HttpClient ) {
    super();
  }

  async getClientesPublicadosData(type: string, selectedDateRange: string,  searchTerm: string, pageNumber: number, pageSize: number) {

    try {
      let params = {
        type: type,
        selectedDateRangeStart: selectedDateRange && JSON.parse(selectedDateRange).start ? JSON.parse(selectedDateRange).start: "",
        selectedDateRangeEnd: selectedDateRange && JSON.parse(selectedDateRange).end ? JSON.parse(selectedDateRange).end: "",
        searchTerm: searchTerm,
        pageNumber: pageNumber,
        pageSize: pageSize
      }
      
      const response = await this.http.put(environment.urlbase + '/admin/v1/company/publish/list', params).toPromise();

      return response as ClientesPublicadosDataComplete;

    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }

  }
}

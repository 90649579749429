import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { environment } from '../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: NbAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        switchMap(authenticated => {
          if (authenticated) {
            return this.authService.getToken().pipe(
              switchMap((token: NbAuthToken) => {
                const authToken = token.getValue();
                
                // Clone a solicitação e adicione o cabeçalho de autorização com o token
                const authRequest = request.clone({
                  setHeaders: {
                    applicationtoken: environment.applicationtoken,
                    Authorization: `Bearer ${authToken}`
                  }
                });

                return next.handle(authRequest);
              })
            );
          } else {
            // Se não estiver autenticado, apenas continue com a solicitação original
            return next.handle(request);
          }
        })
      );
  }
}

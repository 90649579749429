import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConversaoDeClienteSummaryData, ConversaoDeClientesData } from '../data/conversao-clientes-upuou';
import { ConversaoClientesChart, ConversaoClientesChartData } from '../data/conversao-clientes-chart';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConversaoDeClientesService extends ConversaoDeClientesData {

  private summary = [
    {
      title: 'Assinado',
      value: 946,
      code: 'subscribe'
    }, 
    {
      title: 'Trial',
      value: 3654,
      code: 'trial'
    },
    {
      title: 'Cancelado',
      value: 654,
      code: 'cancel'
    }
  ];

  constructor(private conversaoClientesChartService: ConversaoClientesChartData,
              private http: HttpClient,
              private toastrService: NbToastrService) {
    super();
  }

  async getConversaoClientesChartSummary(selectedDateRange: string) {

    try {

      let params = {
        selectedDateRangeStart: selectedDateRange && JSON.parse(selectedDateRange).start ? JSON.parse(selectedDateRange).start: "",
        selectedDateRangeEnd: selectedDateRange && JSON.parse(selectedDateRange).end ? JSON.parse(selectedDateRange).end: "",
      }

      const response = await this.http.put(environment.urlbase + '/admin/v1/company/subscription/summary', params).toPromise();

      return response as any[];
    } catch (error) {
      this.toastrService.danger(error);
      return error;
    }

  }

  async getConversaoClientesChartData(selectedDateRange: string, period: string) {
    return await this.conversaoClientesChartService.getConversaoClientesData(selectedDateRange, period);
  }
}

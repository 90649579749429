import { Observable } from 'rxjs';

export interface ClientesPublicados {
  name: string;
  companyLegalRegister: string;
  companyId: string;
  createdAt: Date;
  public: boolean;
}

export interface ClientesPublicadosDataComplete {
  data: ClientesPublicados[];
  totalRecords: number;
  totalRecordsPage: number;
  hasNext: boolean;
}

export abstract class ClientesPublicadosData {
  abstract getClientesPublicadosData(period: string, selectedDateRange: string, searchTerm: string, pagesNumber?: number, pageSize?: number): Promise<ClientesPublicadosDataComplete>;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbComponentStatus, NbMenuBag, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { Subject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService, NbTokenService } from '@nebular/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  token: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { id: 1, title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private nbAuthService: NbAuthService,
              private nbTokenService: NbTokenService,
              private http: HttpClient,
              private router: Router,
              private toastrService: NbToastrService) {

  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.nbAuthService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload();
        this.token = token.getValue();
      }
    });

    this.menuService.onItemClick()
    .subscribe(
      (menu:NbMenuBag) => {
        if (menu.item['id'] == 1) {
          this.logOut();
        }
      }
    );    

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logOut() {
    const data = {}; // Dados a serem enviados no corpo da requisição
    const strategieConfigured = this.nbAuthService['strategies'][0];

    this.router.navigate([strategieConfigured.options.logout.redirect.success]);
    this.nbTokenService.clear();
    this.postData(strategieConfigured, data).subscribe(
      (response) => {},
      (error) => {
        this.showToastError('danger', error);
        console.error('Erro:', error);
      }
    );

  }

  showToastError(status: NbComponentStatus, error: any) {
    this.toastrService.show(status, `Erro: ${error.message}`, { status });
  }


  postData(strategieConfigured: any, data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    return this.http.post(strategieConfigured.options.baseEndpoint + strategieConfigured.options.logout.endpoint, data, { headers });
  }
}
